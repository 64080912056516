/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useGetProducts,
  useGetProductsByCategoryPath,
  UseGetProductsReturn,
} from 'hooks/magentoHooks';
import {
  ProductInterface,
  ProductFieldsFragment,
} from 'graphql/generated/magentoApi';
import { DataView } from 'primereact/dataview';
// import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';
import { SliderValueType } from 'primereact/slider';
import FocusLock from 'react-focus-lock';
import { sortProductByName } from 'utils/helper';
import { getMarketContent } from 'utils/marketContent';
import Spinner from 'components/Spinner';
import { Skeleton } from 'primereact/skeleton';
import { ProductFilter } from 'components/product-filter/ProductFilter';
import { ProductBreadcrumbTree } from 'components/product-breadcrumb-tree/ProductBreadcrumbTree';
import ProductCard from '../../pages/ProductCard';
import './CollectionProductList.scss';

interface companyVendors {
  name?: string;
}

export default ({
  collectionURL,
  shouldShowAside,
  setLoading,
  isMobile,
  menuModel,
  setMenuModel,
  collectionCategorylist,
}) => {
  const { rootCategory } = getMarketContent();
  const [searchParams] = useSearchParams();

  const isLocalTest = searchParams.get('env') === 'local';

  const { fetching, products: initialProducts }: UseGetProductsReturn =
    useGetProducts();

  const [categoryPaths, setCategoryPaths] = useState<string[]>([
    `marketplace/${rootCategory}/${collectionCategorylist}`,
  ]);

  const { products: productsByParams, fetching: fetchingProductsByParams } =
    useGetProductsByCategoryPath({
      categoryPaths,
      page: 1,
      perPage: 300,
    });

  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [selectedVendors, setSelectedVendors] =
    useState<Array<companyVendors> | null>(null);
  const [collectionProducts, setCollectionProducts] = useState<
    ProductFieldsFragment[]
  >([]);

  const itemTemplate = (product: ProductInterface & { __typename: string }) => (
    <ProductCard product={product} isLocalTest={isLocalTest} />
  );

  const maxProductPrice =
    productsByParams.sort(
      (a, b) =>
        (b.price_range.maximum_price?.final_price.value || 0) -
        (a.price_range.maximum_price?.final_price.value || 0),
    )[0]?.price_range.maximum_price?.final_price.value || 0;

  const [priceRange, setPriceRange] = useState<SliderValueType>([
    0,
    maxProductPrice,
  ]);

  useEffect(() => {
    setCategoryPaths([`marketplace/${rootCategory}/${collectionCategorylist}`]);
  }, [collectionCategorylist, rootCategory]);

  useEffect(() => {
    let filterProducts: Array<ProductFieldsFragment> = [
      ...productsByParams.sort(sortProductByName),
    ];

    if (
      searchParams.get('collections') &&
      searchParams.get('collections')?.toLowerCase() !==
        categoryPaths[0].toLowerCase()
    ) {
      setCategoryPaths([searchParams.get('collections') as string]);
    }
    if (!fetching && !fetchingProductsByParams) {
      if (!collectionProducts.length) {
        setCollectionProducts(filterProducts.sort(sortProductByName));
      }
      filterProducts = filterProducts.filter(
        item =>
          (item?.price_range.maximum_price?.final_price?.value || 0) >=
            priceRange[0] &&
          (item?.price_range.maximum_price?.final_price?.value || 0) <=
            priceRange[1],
      );

      if (selectedVendors) {
        filterProducts = filterProducts.filter(item =>
          selectedVendors.some(
            vendorItem => item?.vendor_company === vendorItem.name,
          ),
        );
      }
      if (
        JSON.stringify(filterProducts) !== JSON.stringify(collectionProducts)
      ) {
        setCollectionProducts(filterProducts.sort(sortProductByName));
      }
      setLoading(true);
    }
  }, [
    fetching,
    selectedVendors,
    priceRange,
    collectionProducts,
    setLoading,
    initialProducts,
    collectionURL,
    productsByParams,
    searchParams,
    categoryPaths,
    fetchingProductsByParams,
  ]);

  return (
    <>
      <div
        className="categories grid"
        style={{ maxWidth: '1480px', margin: '0 auto' }}
      >
        {shouldShowAside && (
          <>
            {isMobile ? (
              <div className="mb-4">
                <Sidebar
                  visible={visibleFilter}
                  onHide={() => setVisibleFilter(false)}
                  className="sidebar-filter"
                  closeOnEscape
                  showCloseIcon={false}
                >
                  {/* //TODO if this is added to help with ProductBreadCrumb rendering it will cause an infinate loop:
                  //    collectionProducts.length >= 1 try comparing if searchparams exists update `${rootCategory}/${collectionCategorylist}` */}
                  <FocusLock>
                    <aside className="col-10 sm:col-12 xl:col-3 px-1 sm:px-3 lg:px-2">
                      {!loadingCategories && categoryPaths.length ? (
                        <>
                          {typeof menuModel[0].items === 'object' &&
                          menuModel[0].items.length !== 0 ? (
                            <Menu
                              model={menuModel}
                              className="w-full shop-categories p-card p-0 mb-3"
                            />
                          ) : (
                            <ProductBreadcrumbTree
                              collectionURL={collectionURL}
                              setLoadingCategories={setLoadingCategories}
                              setMenuModel={setMenuModel}
                              categoryPaths={categoryPaths}
                              showHome
                            />
                          )}
                          <ProductFilter
                            setVisibleFilter={setVisibleFilter}
                            setSelectedVendors={setSelectedVendors}
                            selectedVendors={selectedVendors}
                            shouldShowAside={shouldShowAside}
                            isMobile={isMobile}
                            products={productsByParams}
                            priceRange={priceRange}
                            setPriceRange={setPriceRange}
                            maxProductPrice={maxProductPrice}
                          />
                        </>
                      ) : (
                        <Skeleton height="200px" borderRadius="4px" />
                      )}
                    </aside>
                  </FocusLock>
                </Sidebar>
              </div>
            ) : (
              <aside className="col-12 xl:col-3 px-3 xl:px-2 collection-aside">
                {!loadingCategories && categoryPaths.length ? (
                  <>
                    {typeof menuModel[0].items === 'object' &&
                    menuModel[0].items.length !== 0 ? (
                      <Menu
                        model={menuModel}
                        className="w-full shop-categories p-card p-0 mb-3 border-none shadow-none"
                      />
                    ) : (
                      <ProductBreadcrumbTree
                        collectionURL={collectionURL}
                        setLoadingCategories={setLoadingCategories}
                        setMenuModel={setMenuModel}
                        categoryPaths={categoryPaths}
                        showHome
                      />
                    )}
                    <ProductFilter
                      setVisibleFilter={setVisibleFilter}
                      setSelectedVendors={setSelectedVendors}
                      selectedVendors={selectedVendors}
                      shouldShowAside={shouldShowAside}
                      isMobile={isMobile}
                      products={productsByParams}
                      priceRange={priceRange}
                      setPriceRange={setPriceRange}
                      maxProductPrice={maxProductPrice}
                    />
                  </>
                ) : (
                  <Skeleton height="200px" borderRadius="4px" />
                )}
              </aside>
            )}
          </>
        )}
        {collectionProducts.length >= 1 ? (
          <DataView
            rows={18}
            value={collectionProducts}
            itemTemplate={itemTemplate}
            layout="grid"
            className={`max-w-full col-12 scalein p-0 ${
              shouldShowAside ? 'xl:col-9' : ''
            }`}
          />
        ) : (
          <div className="max-w-full m-auto">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};
