import { DropdownProps } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

// globals loaded when we append the popup.js script
declare global {
  let modalBaseUrl: string;
  function buyNowModal(sku: any, affiliate: any): void;
  function addToCartModal(sku: any, affiliate: any): void;
  function showProductModal(sku: any, affiliate: any): void;
  function showCartModal(id: any): void;
  function showEmailSignupModal(affiliate: any): void;
}

export interface ModalBaseUrlInterface {
  name: string;
  url: string;
  matomoSiteId: string;
  domains: string[];
  matomoUrl: string;
}

export const tenXMatomoUrl = 'https://tenxhealth.matomo.cloud';
export const crwdMarketMatomoUrl = 'https://crwdmarket.matomo.cloud';
export const defaultUrl = 'https://cart.crwd.market';
export const defaultMatomoSiteId = '1';
export const defaultDomains = ['*.crwd.market', '*.advancedmedicine.market'];
export const modalBaseUrlOptions: ModalBaseUrlInterface[] = [
  {
    name: 'Advanced Medicine',
    url: 'https://advancedmedicine.market',
    matomoSiteId: '8',
    domains: ['*.crwd.market', '*.advancedmedicine.market'],
    matomoUrl: crwdMarketMatomoUrl,
  },
  {
    name: '10x Health',
    url: 'https://10xhealthnow.com',
    matomoSiteId: '1',
    domains: ['*.10xhealthnow.com', '*.10xhealth.market'],
    matomoUrl: tenXMatomoUrl,
  },
  {
    name: '10X Cyber Privacy Marketplace',
    url: 'https://10xcyber.market',
    matomoSiteId: '1',
    domains: ['*.crwd.market', '*.10xcyber.market'],
    matomoUrl: tenXMatomoUrl,
  },
  {
    name: 'Interactive Media Marketplace',
    url: 'https://imedia.market',
    matomoSiteId: '9',
    domains: ['*.crwd.market', '*.imedia.market'],
    matomoUrl: crwdMarketMatomoUrl,
  },
];

if (window.location.href.includes('localhost')) {
  modalBaseUrlOptions.push({
    name: 'Localhost Test',
    url: 'http://localhost:3000',
    matomoSiteId: '1',
    domains: ['*.crwd.market', '*.imedia.market'],
    matomoUrl: crwdMarketMatomoUrl,
  });
}

if (window.location.href.includes('dev')) {
  modalBaseUrlOptions.push({
    name: 'Advanced Medicine',
    url: 'https://dev.advancedmedicine.market',
    matomoSiteId: '8',
    domains: ['*.crwd.market', '*.advancedmedicine.market'],
    matomoUrl: crwdMarketMatomoUrl,
  });
}

export const selectedModalBaseOptionTemplate = (
  option: ModalBaseUrlInterface,
  props: DropdownProps,
) => {
  const { placeholder } = props;
  if (option) {
    return (
      <div className="flex">
        <div className="white-space-normal flex align-items-center">
          {option?.name}
        </div>
      </div>
    );
  }

  return <span>{placeholder}</span>;
};

export function useLoadProductPopupJs(
  baseUrl: string,
  matomoSiteId: string,
  domains: string[],
  matomoUrl: string,
): string {
  const [hasAppendedPopupJs, setHasAppendedPopupJs] = useState(false);

  useEffect(() => {
    if (!hasAppendedPopupJs) {
      const script = document.createElement('script');
      script.src = '/tools/productpopup.js';
      document.body.appendChild(script);
      setHasAppendedPopupJs(true);
      return () => {
        document.body.removeChild(script);
      };
    }
    return undefined;
  }, [hasAppendedPopupJs]);

  useEffect(() => {
    if (hasAppendedPopupJs && baseUrl) {
      // eslint-disable-next-line no-eval
      eval(`modalBaseUrl = '${baseUrl}';`);
    }
  }, [hasAppendedPopupJs, baseUrl]);

  const domainsString = `[${domains.map(domain => `'${domain}'`).join(',')}]`;
  const urlString = `'${baseUrl || defaultUrl}'`;
  const siteIdString = `'${matomoSiteId || defaultMatomoSiteId}'`;
  const matomoUrlString = `'${matomoUrl || crwdMarketMatomoUrl}'`;

  return `<script src="${baseUrl}/tools/productpopup.js"></script>
<script>
  initMatomo(${urlString},${siteIdString},${domainsString},${matomoUrlString})
</script>`;
}
